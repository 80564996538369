<template>
  <d-container fluid>
    <pageTitle subtitle="Account Management"
               title="Gebruikers Overzicht"/>
    <d-row>
      <vCard :col="8" header="Gebruikers" :fluid="true">
        <vTable :fields="fields" :actions="actions" :data="data"/>
      </vCard>
      <vCard :col="4" header="Gebruikers" :fluid="false">
      </vCard>
    </d-row>
  </d-container>
</template>

<script>
export default {
  name: 'userOverview',
  data() {
    return {
      actions: [
        {
          icon: '<i class="material-icons">visibility</i>',
          fn: (user) => {
            this.$router.push({name: 'dashboard/inkoop/userManagement/user', params: {user_id: user.id}})
          }
        }
      ],
      data: null,
      fields: [
        {
          name: 'email',
          title: 'Gerbuikersnaam',
          sortField: 'name'
        },
        'actions'
      ],
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    async getUsers() {
      let {data} = await this.$api.user.list()
      this.data = data
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
